
import { action, makeObservable, observable } from "mobx";

import filter from "lodash-es/filter";
import lodashIncludes from "lodash-es/includes";
import { createContext, useContext } from "react";
import { SITE_OPTIONS, SiteOptionsType } from "src/config/site-options";
import { getItemAsync, setItemAsync } from "src/core/utils/storage";

const STORAGE_NAME = "site-options";
class SiteOptionsStoreClass {
  options: SiteOptionsType[] = [];

  hasOption = (o: SiteOptionsType) => {
    return lodashIncludes(this.options, o);
  };

  option = {
    get: () => {
      return filter(this.options, (f) => SITE_OPTIONS.includes(f));
    },
    remove: (v: SiteOptionsType) => {
      if (this.hasOption(v)) {
        const o = this.option.get();
        this.options = filter(o, (f) => f !== v);
        setItemAsync(STORAGE_NAME, [...this.options]);
      }
    },
    add: (v: SiteOptionsType) => {
      if (!this.hasOption(v)) {
        this.options = [...this.options, v];
      }
      setItemAsync(STORAGE_NAME, [...this.options]);
    },
  };

  toggle = (v: SiteOptionsType) => {
    /** exclude any value which is not in SITE_OPTIONS */

    if (this.hasOption(v)) {
      this.option.remove(v);
      return;
    }
    this.option.add(v);
  };


  loadData = async () => {
    const o: SiteOptionsType[] = await getItemAsync(STORAGE_NAME);
    if (o) {
      this.options = o;
    }
  };

  constructor() {
    this.loadData();

    makeObservable(this, {
      options: observable,
      hasOption: observable,
      toggle: action,
    });
  }
}

export const SiteOptionsStore = new SiteOptionsStoreClass();

const SiteOptionsContext = createContext(SiteOptionsStore);

type Props = {
  children: React.ReactNode;
};
export const SiteOptionsProvider = ({ children }: Props) => {
  return (
    <SiteOptionsContext.Provider value={SiteOptionsStore}>{children}</SiteOptionsContext.Provider>
  );
};

export const useSiteOptionsContext = () => {
  const context = useContext(SiteOptionsContext);

  if (!context) throw new Error("useSiteOptionsContext must be use inside SiteOptionsProvider");

  return context;
};
