
// ----------------------------------------------------------------------

export default function Badge() {
  return {

    MuiBadge: {

      styleOverrides: {
        badge: {
          // color: "white"
        },
        dot: {
          width: 10,
          height: 10,
          borderRadius: "50%"
        }
      },
    },

  }
}
