import  { createContext, useContext } from "react";
import UserStore from "./user_store";


const UserContext = createContext(UserStore);

type Props = {
  children: React.ReactNode;
};
export const UserProvider = ({ children }: Props) => (
  <UserContext.Provider value={UserStore}>{children}</UserContext.Provider>
);

export const useUserContext = () => {
  const context = useContext(UserContext);

  if (!context) throw new Error("useUserContext must be use inside UserProvider");

  return context;
};
export { UserStore };
