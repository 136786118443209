
import { fields } from "../../schema";


export const AddressSchema = {
    // $id: "address",
    type: ["object", "null"],
    additionalProperties: false,
    properties: {

        formatted_address: fields.string.medium /* formatted_address is not always available so dont require it */,
        lat: fields.lat,
        lng: fields.lat,
        timeZone: fields.string.short,
        map: fields.string.medium,
        street_view: fields.string.medium,

        /** unused remove at some point */
        place_id: fields.string.short,
        address: { type: "string" }, // many addresses are failing, research later
        city: fields.string.short,
        state: fields.string.short,
        postal_code: { type: "string" },
        country: fields.string.short,
        premise: fields.string.short,
    },
    required: ["lat", "lng", "formatted_address"],
};