import { lazy } from "react";

import Loadable from "src/routes/sections/loadable";

const SettingsView = Loadable(lazy(() => import("./settings-view")));
const NotificationsPage = Loadable(lazy(() => import("./notifications/page")));
const PrivacyPage = Loadable(lazy(() => import("./privacy")));
const AuthGuard = Loadable(lazy(() => import("src/modules/auth/guard/auth-guard")));

export const settingsRoutes = [
  {
    path: "/settings",
    element: (
      <AuthGuard>
        <SettingsView />
      </AuthGuard>
    ),
    // element: (<AuthGuard><SettingsView /></AuthGuard>),
    children: [
      { path: "privacy", element: <PrivacyPage /> },
      { path: "notifications", element: <NotificationsPage /> },
      { path: "", element: <NotificationsPage /> },
    ],
  },
];
