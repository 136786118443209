import { assignValues } from "src/model/shared/utils/assign-values";



export class UserApi {
    name: string;

    props: {
        about: string;
    };

    isPublic?: boolean;

    fcm?: string[]

    logo?: string;

    cover?: { url: string }[];

    constructor(_props: { [key: string]: any }) {
        if (_props) {
            this.update(_props);
        }
    }

    update(_props: { [key: string]: any }) {
        assignValues(this, _props);
    }

}