import forEach from "lodash-es/forEach";

import { AddressType } from "./types";
import { assignValues } from "../utils/assign-values";
import validator from "../../ajv/validator";
import { AddressSchema } from "./address-schema";
import { PlainObject } from "../..";

const FIELDS = [
  "place_id",
  "lat",
  "lng",
  "address",
  "city",
  "state",
  "postal_code",
  "premise",
  "country",
  "timeZone",
  "formatted_address",
  "map", "street_view"

];

export class Address implements AddressType {
  place_id?: string;

  lat: number;

  lng: number;

  address?: string;

  city: string;

  state: string;

  postal_code?: number;

  premise?: any;

  country: string;

  timeZone?: string;

  formatted_address?: string;

  map?: string;

  street_view?: string;

  constructor(_props?: PlainObject) {
    if (_props) {
      this.update(_props);
    }
  }

  static valid(_props?: PlainObject) {
    try {
      validator.validate(_props || {}, AddressSchema);

      return new Address(_props);
    } catch (err) {
      // console.warn(err);
      return undefined;
    }
  }

  update(_props?: PlainObject) {
    if (_props) {
      assignValues(this, _props, FIELDS);
    }
    if (!this.formatted_address) {
      this.formatted_address = combine(
        this.address,
        this.premise,
        this.city,
        ",",
        this.state,
        this.postal_code
      );
    }
  }

  validate() {
    // validator.validate(this, AddressSchema);
  }

  // get = {
  //    distance: {
  //     unit: (distanceInMeters: number, _unit_: "M" | "K"  = "K")=>{
  //        if(_unit_ === "M"){
  //          const mi = distanceInMeters * 0.000621371192;
  //          return `${mi.toFixed(1)} mi`;
  //        }
  //        const km = distanceInMeters / 1000;
  //        return `${km.toFixed(1)} km`;
  //     },
  //     fromCoordinates: (lat2: number, lon2: number, unit: "M" | "K" | "N" = "K") =>{
  //       return getDistanceFromCoordinates(this.lat, this.lng, lat2, lon2, unit);

  //     }
  //    }
  // }
}


export function combine(...params: any[]) {
  let str = "";
  forEach(params, (v) => {
    if (v && typeof v === "string") {
      str += `${v} `;
    }
  });

  /* replace double spaces */
  return str.replace(/\s\s+/g, " ")?.trim();
} 