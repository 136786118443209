export const userConfig = {
  data: {
    isPublic: true,
    uid: undefined,
    name: "Anonymous",
    logo: "/public/default/avatar.png",
    cover: [{ url: "/public/default/avatar.png" }],
    props: {
      about: "",
    },
  },
  location: {
    lat: 40.7994931,
    lng: -73.9530308,
    formatted_address: "New York, NY 10026, USA",
    // address: "115 W 111th St",
    // city: "New York",
    // state: "NY",
    // postal_code: "10026",
    // country: "US",
  },
};
