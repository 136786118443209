
import memoize from "lodash-es/memoize";
import { axiosErrorLogger } from "./error-logger";
import { AxiosProps } from "./types";
import { apiResponse } from "./api-response";




// export const cancelToken = () => {
//   const cancelTokenSource = instance.CancelToken.source();
//   return cancelTokenSource;

//   // api(url, false, axiosToken.token);
//   // cancelTokenSource.cancel();
// };




/**
 * Usage
  `if(HAS_MORE){
     HAS_MORE = axios.hasMore(data, limit)
  }`
 * @param data u
 * @param limit 
 * @returns 
 */
const hasMore = (data: any[], limit: number) => {
  if (data && data.length === limit) {
    return true;
  }
  return false;
};
const getPage = (data: any[], limit: number) => {
  return data.length / limit;
};


const getApiResponse = async (_memoKey: string, url: string, data?: any, first_row = false, axiosProps?: AxiosProps) => {
  return apiResponse("get", url, data, first_row, axiosProps); // props
};

const memoizedApiResponse = memoize(getApiResponse);
const API = {
  cached: (url: string, data?: any, first_row = false, axiosProps?: AxiosProps) =>
    memoizedApiResponse(JSON.stringify({ url, data }), url, data, first_row, axiosProps),
  /** axios.cache?.clear() | axios.cache?.delete(JSON.stringify()) */
  // clear:  memoizedApiResponse.cache,
  cache: {
    clear: () => {
      if (memoizedApiResponse.cache?.clear) {
        memoizedApiResponse.cache?.clear();
      }
    },
    delete: (url: string, data?: any) => {
      if (memoizedApiResponse.cache?.delete) {
        memoizedApiResponse.cache?.delete(JSON.stringify({ url, data }));
      }
    },
  },
  get: (url: string, data?: any, first_row = false, axiosProps?: AxiosProps) => apiResponse("get", url, data, first_row, axiosProps),
  head: (url: string, data: any, first_row = false) => apiResponse("head", url, data, first_row),
  options: (url: string, data: any, first_row = false) =>
    apiResponse("options", url, data, first_row),
  delete: (url: string, data: any, first_row = false) =>
    apiResponse("delete", url, data, first_row),
  post: (url: string, data: any, first_row = false) => apiResponse("post", url, data, first_row),
  put: (url: string, data: any, first_row = false) => apiResponse("put", url, data, first_row),
  patch: (url: string, data: any, first_row = false) => apiResponse("patch", url, data, first_row),
  hasMore,
  getPage,
  error: axiosErrorLogger,
};

const api = API.cached;
export default API;
export { api };

