
export const navigate = (history: any, url: string, target = "_blank") => {
    if (url.includes(":")) {
        window.open(url, target, "noopener");

    } else if (history) {
        history(url);
        // const handle = window.open(url, target, "noopener");
        // if(!handle){
        //   warning("Window wasn't allowed to open popup, ");
        // }
    }
};
export const isMobile = () => {
    return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
    // return navigator.userAgentData.mobile;
};

export function isExternalLink(path: string) {
    if (typeof path === "string") {
        return path.includes("http");
    }
    return false;

}
