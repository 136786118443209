import Box from "@mui/material/Box";
import { m } from "framer-motion";
import { alpha, styled } from "@mui/material/styles";
import { LogoProps } from "src/components/logo/types";
import Logo from "./Logo";
// ----------------------------------------------------------------------

const RootStyle = styled(Box)(({ center }: LogoProps) => ({
  height: center ? "70%" : "auto",
  // minHeight: "50vh", //affects empty containers like app.js or routes
  // marginTop: "10px",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  padding: 30,
}));
const LoadingScreen = ({ sx, size = 45, ...other }: LogoProps) => {
  const boxSize = size * 1.3;
  const border = boxSize * 0.07;

  return (
    <RootStyle {...other}>
      <m.div
        initial={{ rotateY: 0 }}
        animate={{ rotateY: 360 }}
        transition={{
          duration: 2,
          ease: "easeInOut",
          repeatDelay: 1,
          repeat: Infinity,
        }}
      >
        <Logo size={size} disabledLink />
      </m.div>

      <Box
        component={m.div}
        animate={{
          scale: [1.2, 1, 1, 1.2, 1.2],
          rotate: [270, 0, 0, 270, 270],
          opacity: [0.25, 1, 1, 1, 0.25],
          borderRadius: ["25%", "25%", "50%", "50%", "25%"],
        }}
        transition={{ ease: "linear", duration: 3.2, repeat: Infinity }}
        sx={{
          width: boxSize,
          height: boxSize,
          borderRadius: "25%",
          position: "absolute",
          border: (theme) => `solid ${border * 0.5}px ${alpha(theme.palette.primary.dark, 0.9)}`,
        }}
      />

      <Box
        component={m.div}
        animate={{
          scale: [1, 1.2, 1.2, 1, 1],
          rotate: [0, 270, 270, 0, 0],
          opacity: [1, 0.25, 0.25, 0.25, 1],
          borderRadius: ["25%", "25%", "50%", "50%", "25%"],
        }}
        transition={{
          ease: "linear",
          duration: 3.2,
          repeat: Infinity,
        }}
        sx={{
          width: boxSize + 10,
          height: boxSize + 10,
          borderRadius: "25%",
          position: "absolute",
          border: (theme) => `solid ${border}px ${alpha(theme.palette.primary.dark, 0.9)}`,
        }}
      />
    </RootStyle>
  );
};

export default LoadingScreen;
