
import { action, makeObservable, observable } from "mobx";

import  { createContext, useContext } from "react";

class LighboxStoreClass {
  src: string | null = null;

  set(_src: string | null) {
    this.src = _src || null;
  }

  constructor() {
    makeObservable(this, {
      set: action,
      src: observable,
    });
  }
}

export const LighboxStore = new LighboxStoreClass();

const LighboxContext = createContext(LighboxStore);

type Props = {
  children: React.ReactNode;
};
export const LightboxProvider = ({ children }: Props) => (
  <LighboxContext.Provider value={LighboxStore}>{children}</LighboxContext.Provider>
);

export const useLighboxContext = () => {
  const context = useContext(LighboxContext);

  if (!context) throw new Error("useFlagContext must be use inside UserProvider");

  return context;
};
