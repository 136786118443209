import map from "lodash-es/map";
import orderBy from "lodash-es/orderBy";

import { action, makeObservable, observable } from "mobx";

import { createContext, useContext } from "react";

import axios from "src/core/utils/axios";

import asyncHandler from "src/core/utils/asyncHandler";
import { Notification } from "src/model/shared/notifications/notification";
import { getUUID } from "src/modules/auth/utils/uuid";
import { getItemAsync, setItemAsync } from "../../core/utils/storage";

// const NOTIFICATIONS: NotificationProps[] = [
//   { title: "hello", body: "holler", isRead: false, changed: "2023-01-01 13:11:11", url: "https://masjidplus.com/static/images/default.png" },
//   { type: "warning", title: "warning hello 1", body: "holler 2", isRead: false, changed: "2023-01-01 13:11:12",src: "/static/images/default.png" },
//   { type: "error", title: "error hello 2", body: "holler 3", isRead: true, changed: "2023-01-01 13:11:13", src: "/public/default/avatar.png" },
//   { title: "hello", body: "holler", isRead: false, changed: "2023-01-01 13:11:11", url: "https://masjidplus.com/static/images/default.png" },
//   { type: "warning", title: "warning hello 1", body: "holler 2", isRead: false, changed: "2023-01-01 13:11:12",src: "/static/images/default.png" },
//   { type: "error", title: "error hello 2", body: "holler 3", isRead: true, changed: "2023-01-01 13:11:13", src: "/public/default/avatar.png" },
//   { title: "hello", body: "holler", isRead: false, changed: "2023-01-01 13:11:11", url: "https://masjidplus.com/static/images/default.png" },
//   { type: "warning", title: "warning hello 1", body: "holler 2", isRead: false, changed: "2023-01-01 13:11:12",src: "/static/images/default.png" },
//   { type: "error", title: "error hello 2", body: "holler 3", isRead: true, changed: "2023-01-01 13:11:13", src: "/public/default/avatar.png" },
// ]
let timer: NodeJS.Timeout;

const UUID = getUUID();

class NotificationsStoreClass {
  token?: string;

  items: Notification[] = [];

  unread: Notification[] = [];

  #sort() {
    this.items = orderBy(this.items, ["sid"], ["desc"]);
  }

  #storage = {
    load: asyncHandler(async () => {
      const res = await getItemAsync("notifications", {}, UUID);
      const { items, token } = res || {};
      if (items) {
        this.items = map(items, (item) => new Notification(item));
        this.#sort();
        this.getUnread();
      }
      if (token) this.token = token;
    }),
    save: () => {
      if (timer) {
        clearTimeout(timer);
      }
      timer = setTimeout(() => {
        setItemAsync("notifications", { items: this.items, token: this.token }, UUID);
      }, 1000);
    },
  };

  add(noti: Notification) {
    if (!noti?.title) {
      return;
    }

    /** printing notifications could get stuck in a loop if console is called within the notification */

    const newItems = [...this.items];
    newItems.unshift(noti);

    this.items = newItems;
    this.#sort();

    this.getUnread();

    //  this.#storage.save();
  }

  remove(noti?: Notification) {
    if (noti) {
      this.items = this.items.filter((f) => f.sid !== noti.sid);
    } else {
      this.items = [];
    }

    this.getUnread();
    // this.#storage.save();
    this.db.delete(noti);
  }

  getUnread() {
    const iUnread = this.items.filter((f) => String(f.isRead) !== "true");

    if (iUnread.length !== this.unread.length) {
      this.unread = iUnread;
    }
    return iUnread;
  }

  isRead(noti?: Notification, read = true) {
    const sids: number[] = [];
    this.items = this.items.map((n) => {
      if (noti) {
        if (n.sid === noti.sid) {
          n.isRead = read;
          sids.push(n.sid);
        }
      } else {
        n.isRead = true;
        sids.push(n.sid);
      }

      return n;
    });

    this.getUnread();
    // this.#storage.save();
    this.db.isRead(sids, read);
    return sids.length > 0;
  }

  db = {
    token: {
      add: asyncHandler(async (token: string) => {
        await axios.post("/user/fcm", { token });
        this.token = token;
        this.#storage.save();
      }),
      delete: asyncHandler(async () => {
        if (!this.token) return;
        await axios.delete("/user/fcm", { token: this.token });
        this.token = undefined;
        this.#storage.save();
      }),
    },
    delete: asyncHandler(async (noti: Notification) => {
      const { sid } = noti || {};
      if (sid) {
        await axios.delete("/user/activity", { sid });
        this.#storage.save();
      }
    }),
    isRead: asyncHandler(async (sids: number[], isRead: boolean) => {
      if (sids && sids?.length > 0) {
        await axios.put("/user/activity", { sids, isRead });
        this.#storage.save();
      }
    }),
    load: asyncHandler(async () => {
      const changed = this.items.reduce((acc, obj) => {
        return obj.changed > acc ? obj.changed : acc;
      }, 0);

      const res = await axios.get("/user/activity", { changed });
      if (res && res.length > 0) {
        const ri = res.map((n) => new Notification(n));
        const items = [...this.items];
        ri.forEach((newNoti) => {
          const index = items.findIndex((tn) => tn.sid === newNoti.sid);

          if (index !== -1) {
            items[index] = newNoti;
          } else {
            items.push(newNoti);
          }
        });
        this.items = items;
        this.#sort();
        this.getUnread();
        this.#storage.save();
      }
    }),
  };

  constructor() {
    this.#storage.load();

    makeObservable(this, {
      add: action,
      remove: action,
      isRead: action,
      token: observable,
      items: observable,
      unread: observable,
    });
  }
}

export const NotificationsStore = new NotificationsStoreClass();
const NotificationsContext = createContext(NotificationsStore);

type Props = {
  children: React.ReactNode;
};
export const NotificationsProvider = ({ children }: Props) => (
  <NotificationsContext.Provider value={NotificationsStore}>
    {children}
  </NotificationsContext.Provider>
);

export const useNotificationsContext = () => {
  const context = useContext(NotificationsContext);

  if (!context) throw new Error("useNotificationContext must be use inside NotificationProvider");

  return context;
};
