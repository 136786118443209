import type { SearchAppBarListItemType } from "../types";
import axios from "src/core/utils/axios";


export const searchGetDataDefault = async (value: string): Promise<SearchAppBarListItemType[]> => {
  if (value) {
    const res = await axios.get("/search/group", { value });

    return res;
  }
  return [];
};