
import { action, makeObservable, observable } from "mobx";

import  { createContext, useContext } from "react";
import { NavDataProps } from "src/core/components/nav-section/types";

 
class NavigationStoreClass {
  items: NavDataProps[] = []

  set =(_items: NavDataProps[])=>{
    this.items = _items;
  }
 

  constructor() {
 

    makeObservable(this, {
      set: action,

      items: observable

    });
  }

}
const NavigationStore = new NavigationStoreClass();
export { NavigationStore };

const NavigationContext = createContext(NavigationStore);

type Props = {
  children: React.ReactNode;
};
export const NavigationProvider = ({ children }: Props) => (
  <NavigationContext.Provider value={NavigationStore}>{children}</NavigationContext.Provider>
);

export const useNavigationContext = () => {
  const context = useContext(NavigationContext);

  if (!context) throw new Error("useNavigationContext must be use inside UserProvider");

  return context;
};
