import forEach from "lodash-es/forEach";
import get from "lodash-es/get";
import hasIn from "lodash-es/hasIn";
import mapKeys from "lodash-es/mapKeys";
import set from "lodash-es/set";
import isEqual from "lodash-es/isEqual";
import { PlainObject } from "../..";



export const getKeys = (props: PlainObject) => {
    const arr: PlainObject[] = [];
    mapKeys(props, (val, key) => {
        if (typeof val !== "function") {
            if (typeof val === "object" && !Array.isArray(val)) {
                const nv = getKeys(val);
                forEach(nv, (o) => {
                    o.key = `${key}.${o.key}`;
                    arr.push(o);
                });
            } else {
                arr.push({ key, val });
            }
        }
    });
    return arr;
};



/* `
* do not use schema during validation. ajv will overwrite IDENTITY or KEYS if they are not provided
example 
  target {eid: 1111, etype: "nid", logo: /hello }
  source {logo: /new-logo}
  after validate target {eid: undefined, etype: undefined, logo: /new-logo}


if(schema){
   if schema is provided and eid is used then eid will be overwritten if not provided in assignValues 
   try{
     validator.validate(source, schema);
   }catch(err){
     console.error("assignValues error:", schema.$id, err, source);
   }
 }
 */
/**
 * @param {PlainObject} target - target object being updated from source
 * @param {PlainObject} source - object containg assignValues
 * @param {SchemaObject} schema - AJV schema object to test values
 * @param {string[]} keys - array of keys to use
 *

 */
export const assignValues = (target: PlainObject, source: PlainObject, keys?: string[], changes?: { key: string, value: any }[]) => {


    if (target && source) {
        /* if target doesnt have keys, then add them */
        if (keys && keys.length > 0) {
            for (const k of keys) {
                if (!(k in target)) {
                    target[k] = undefined;
                }
            }
        }

        const targetKeys = getKeys(target);
        targetKeys.forEach((o) => {


            if (!keys || keys.includes(o.key)) {
                let newValue = get(source, o.key);
                if (newValue === null) newValue = undefined;

                let originalValue = get(target, o.key);
                if (originalValue === null) originalValue = undefined;


                if (hasIn(source, o.key)) {
                    /*
                    if(originalValue !== newValue) is false for arrays
                    */
                    if (!isEqual(originalValue, newValue)) {

                        // console.log("not same",originalValue, newValue);
                        // let nc = changes?.filter(itm=>itm.key !== o.key);
                        // console.log("nc",o.key,nc);
                        if (changes) {
                            for (let index = changes.length - 1; index >= 0; index -= 1) {
                                if (changes[index].key === o.key) {
                                    changes.splice(index, 1);
                                }
                            }
                            changes.push({ key: o.key, value: newValue });
                        }

                        // }else{
                        //   console.log("is same",originalValue, newValue);
                    }
                    set(target, o.key, newValue);
                    // }else{
                    //   console.log("key not in source::::::::",o.key);
                }
            }
        });
    }

    return target;
};
