import { lazy } from "react";

import { Toaster } from "sonner";

import { observer } from "mobx-react-lite";
import MotionLazy from "src/core/components/animate/motion-lazy"; // .3mb

import { useSiteOptionsContext } from "src/context/siteOptionsContext";
import ErrorBoundary from "src/core/components/loading/ErrorBoundary";
import ThemeSettings from "src/core/theme/settings/ThemeSettings";




const AutoPageviews = lazy(() => import("src/utils/tracker"));
const MenuView = lazy(() => import("src/modules/menu/menu-view"));
const FormsView = lazy(() => import("src/views/forms/forms-view"));
const LazyLighbox = lazy(() => import("src/core/components/lightbox/LazyLighbox"));

/** can load later */
const Walkthrough = lazy(() => import("src/core/components/custom-joyride/walkthrough"));
const LocationTracker = lazy(() => import("src/modules/auth/location-tracker"));
const AutoLogin = lazy(() => import("src/modules/auth/auto-login"));
const PushNotificationsListener = lazy(
  () => import("src/modules/notifications/push-notifications/push-notifications-listener")
);

// const Router = lazy(() => import("src/routes/sections"));
import LoadingScreen from "src/components/logo/LoadingScreen";
import Router from "src/routes/sections";

function App() {
  // const [loaded, setLoaded] = useBoolean(false);
  return (
    <ThemeSettings>
      <Toaster
        position="bottom-center"
        richColors
        closeButton
        expand={false}
        toastOptions={{
          duration: 8000,
          unstyled: true,
          style: {
            width: "max-content",
            maxWidth: "90vw",
          },
        }}
      />
      <MotionLazy>
        <ErrorBoundary component="App:Router"
          fallback={<LoadingScreen />}
        // onLoading={(loading) => {
        // }}
        >
          <Router />
        </ErrorBoundary>
        {/* {loaded && */}
        <ErrorBoundary component="App:Other">
          <LocationTracker />
          <AutoPageviews />
          <MenuView />
          <LazyLighbox />
          <FormsView />
          <SideComponents />
        </ErrorBoundary>
      </MotionLazy>
    </ThemeSettings>
  );
}

export default App;

const SideComponents = observer(() => {
  const { hasOption } = useSiteOptionsContext();

  return (
    <ErrorBoundary component="App:SideComponents">
      {/** add auto login and push notifications and everything else which isnt used by everyone */}
      {hasOption("walkthrough") && <Walkthrough />}
      {hasOption("auto-login") && <AutoLogin />}
      {hasOption("auto-login") && <PushNotificationsListener />}
    </ErrorBoundary>
  );
});
