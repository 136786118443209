import { lazy } from "react";
// import DashboardLayout from "src/layouts/dashboard/layout";
// import AuthGuard from "src/modules/auth/guard/auth-guard";
const DashboardLayout = lazy(() => import("src/layouts/dashboard/layout"));
const AuthGuard = lazy(() => import("src/modules/auth/guard/auth-guard"));

const AccountPage = lazy(() => import("src/views/user/account/AccountPage"));
const PublicProfile = lazy(() => import("src/views/user/profile/PublicProfile"));
const PrivateProfile = lazy(() => import("src/views/user/profile/PrivateProfile"));

export const userRoutes = [
  {
    path: "/user",
    subheader: "user",
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: "",
        element: <PrivateProfile />,
      },
      {
        path: "account",
        element: <AccountPage />,
        children: [{ path: ":page" }],
      },
      {
        path: ":uuid",
        element: <PublicProfile />,
      },
    ],
  },
];
