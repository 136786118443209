import { Theme } from "@mui/material/styles";
import { SkeletonProps } from "@mui/material/Skeleton";

// ----------------------------------------------------------------------

export default function Skeleton(theme: Theme) {
  return {
    MuiSkeleton: {
      defaultProps: {
        animation: "wave",
      },
      styleOverrides: {
        root: ({ ownerState }: { ownerState: SkeletonProps }) => {
          const { variant } = ownerState || {}
          // console.log("ownerState", ownerState)
          return {
            borderRadius: variant === "rounded" ? `${theme.shape.borderRadius}px` : undefined,
          }
        }

      },
    },
  };
}
