import Typography from "@mui/material/Typography";
import { Suspense, SuspenseProps } from "react";
import { ErrorBoundary as ReactErrorBoundry } from "react-error-boundary";
import LoadingIndicator from "src/core/components/loading/loading-indicator";
import axios from "src/core/utils/axios";
import { ClientError } from "src/model/shared/client-error";

const DefaultErrorFallback = ({ message }: any) => {

  return (
    <div role="alert">
      <Typography variant="h5" color="error">
        {/* {message || "We've updated the app, please reload this page"} */}
        {message || "Oooops, 😔"}
      </Typography>
      {/* <pre>{error.message}</pre> */}
      {/* <pre>{error?.message}</pre>
      <button onClick={resetErrorBoundary}>Try again</button> */}
    </div>
  );
};

interface ErrorBoundaryProps {
  component: string;
  props?: any;

  children: React.ReactNode;
  fallback?: any;
  errorMessage?: string;
  resetKeys?: any[];
  suspense?: SuspenseProps;
  disableApiPost?: boolean;
  onLoading?: (loading: boolean) => void;
  [key: string]: any;
}
// React.ComponentType<FallbackProps>
/**
 * ErrorBoundary on error displays FallbackComponent and send data to api
 * @date 11/21/2022 - 10:03:13 AM
 *
 * @param {ReactNode} suspense props (size, fallback, setIsLoading)
 * @param {string[]} resetKeys keys which reset the error boundary
 * @param {ReactNode} fallback on error function to execute or component to render
 * @param {boolean} disableApiPost disable posting error to api
 * defaults to "Something went wrong"
 * @param {ReactNode} fallbackRender allows you to inline your error fallback UI into the component that's using the ErrorBoundary
 * @param {onReset} ()=>setState(defaultValues)
 * @param {rest} ...rest - props used to send to api
 * @param {any} rest.data
 * @param {any} rest.props
}
 * @returns {*}
@example `
  FallbackComponent={<ComponentWithProps />)=>getData()}
  fallbackRender={({error, resetErrorBoundary})=>getData()}
  fallback={<div>Oh no</div>}
  resetKeys={[JSON.stringify(values)]}

 */

const ErrorBoundary = ({
  suspense,
  resetKeys,
  children,
  fallback,
  // fallbackRender,

  FallbackComponent,
  errorMessage,
  component,
  props,
  onLoading,
  disableApiPost,
}: ErrorBoundaryProps) => {
  const logError = (error: any, _: any) => {
    // const { isAuthenticated, user } = UserStore;

    const ce = new ClientError({
      error,
      props: {
        component,
        props,
      },
      title: "ErrorBoundary errorHandler", // error.message
    });
    console.debug("ClientError", ce);

    try {
      // console.debug("ErrorBoundary props :::", rest);
      // console.debug("ErrorBoundary error :::", error);
      // console.debug("ErrorBoundary stack :::", info.componentStack);
    } catch {
      /** */
    }
    if (import.meta.env.PROD) {
      axios.error(ce);
    }
  };
  if (errorMessage) {
    fallback = <DefaultErrorFallback message={errorMessage} />;
  }
  if (!FallbackComponent && !fallback) {
    // if (!fallbackRender && !FallbackComponent) {
    FallbackComponent = DefaultErrorFallback;
  }

  return (
    <ReactErrorBoundry
      fallback={fallback}
      //  FallbackComponent={DefaultErrorFallback}
      // FallbackComponent={fallbackRender ? undefined : FallbackComponent || DefaultErrorFallback}
      FallbackComponent={FallbackComponent || DefaultErrorFallback}
      onError={disableApiPost ? undefined : logError}
      resetKeys={resetKeys}
    >
      <Suspense {...suspense} fallback={<LoadingIndicator onIsLoading={onLoading} />}>
        {children}
      </Suspense>
    </ReactErrorBoundry>
  );
};

export default ErrorBoundary;
