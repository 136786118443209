import { Theme } from "@mui/material/styles";

// ----------------------------------------------------------------------

export default function List(theme: Theme) {
  return {
    // MuiListItem: {
    //   styleOverrides: {
    //     root: {
    //       // padding: theme.spacing(1),
    //       // "& .MuiListItemButton":{ pr:0 }
    //     },
    //   },
    // },
    // MuiListItemButton: {
    //   styleOverrides: {
    //     root: {
    //       padding: 0,
    //     },
    //   },
    // },
    MuiListSubheader: {
      styleOverrides: {
        root: {
          backgroundColor: "transparent"
        },
      },
    },
    MuiListItemIcon: {
      styleOverrides: {
        root: {
          color: "inherit",
          minWidth: "auto",
          marginRight: theme.spacing(2),
        },
      },
    },
    MuiListItemAvatar: {
      styleOverrides: {
        root: {
          minWidth: "auto",
          marginRight: theme.spacing(2),
        },
      },
    },
    MuiListItemText: {
      styleOverrides: {
        root: {
          marginTop: 0,
          marginBottom: 0,
        },
        multiline: {
          marginTop: 0,
          marginBottom: 0,
        },
      },
    },
  };
}
