
import assign from "lodash-es/assign";
import forEach from "lodash-es/forEach";
import isString from "lodash-es/isString";

function isNumeric(value: string) {
    return /^-?\d+$/.test(value);
}

export const axiosDataParser = (data: any, first_row: boolean) => {
    // data = _.cloneDeep(data);
    // let data = fromJSON(toJSON(d));
    if (isString(data)) return data;

    if (data && data[0]) {
        forEach(data, (o) => {
            // parse all the objects
            forEach(o, (value, key) => {
                if (value === null || value === "null") {
                    o[key] = undefined;
                } else if (isString(value)) {
                    if (isNumeric(value)) {
                        o[key] = Number(value);
                    } else {
                        try {
                            const nv = JSON.parse(value);
                            assign(o[key], { [key]: nv });
                        } catch {/**/ }
                    }
                }
            });
        });

        if (first_row) {
            return data[0];
        }
        return data;

    }
    // console.log("not data!!!!!!!!!!!!", data);
    return data;

};