
import { action, makeObservable, observable } from "mobx";

import { createContext, useContext } from "react";
import type { ListItemType } from "src/core/components/list/types";

type MenuOptionsType = {
  options?: ListItemType[];
  getOptions?: () => ListItemType[];
  getOptionsAsync?: () => Promise<ListItemType[]>;
};

type MenuPropsTypes = {
  side: "right" | "left";
};
const defaultProps: MenuPropsTypes = {
  side: "right",
};
class MenuClass {
  anchorEl: null | any;

  //   anchorEl: null | HTMLElement;
  options?: MenuOptionsType["options"];

  //   getOptions?: MenuOptionsType["getOptions"]
  //   getOptionsAsync: MenuOptionsType["getOptionsAsync"]
  props: MenuPropsTypes = defaultProps;
  /**
     *
     * @param Menu
     * @param listOptions string[] || {primary: "hello", icon:"star"}
     * @param listOptions.options?: ListItemType[];
     * @param listOptions.getOptions?: ()=> ListItemType[];
     * @param listOptions.getOptionsAsync?: ()=> Promise<ListItemType[]>;
    
     * @param props
     * @examples: 
      MenuStore.setMenu("event", {header: "my custom header"}, { node: node })
      MenuStore.setMenu("event", title, { reid: 123})
  
      */

  set(anchorEl?: any, listOptions?: MenuOptionsType, props?: MenuPropsTypes) {
    this.props = { ...defaultProps, ...props };
    this.anchorEl = anchorEl?.currentTarget || anchorEl?.target || anchorEl;

    const { options, getOptions, getOptionsAsync } = listOptions || {};
    if (options) {
      this.options = options;
    } else if (getOptions) {
      this.options = getOptions();
    } else if (getOptionsAsync) {
      getOptionsAsync()
        .then((res) => {
          this.options = res;
        })
        .catch(() => { });
    }
  }

  clear() {
    // try{
    this.anchorEl = undefined;
    this.options = undefined;
    // }catch {/** */ }
  }

  constructor() {
    makeObservable(this, {
      set: action,
      clear: action,

      anchorEl: observable,
      props: observable,
      options: observable,
    });
  }
}

export const MenuStore = new MenuClass();

const MenuContext = createContext(MenuStore);

type Props = {
  children: React.ReactNode;
};

export const MenuProvider = ({ children }: Props) => (
  <MenuContext.Provider value={MenuStore}>{children} </MenuContext.Provider>
);

export const useMenuContext = () => {
  const context = useContext(MenuContext);

  if (!context) throw new Error("useMenuContext must be use inside UserProvider");

  return context;
};
