import MuiTooltip, { TooltipProps as MuiTooltipProps } from "@mui/material/Tooltip";

type TooltipProps = MuiTooltipProps & {
  title?: string;
};
export default function ArrowTooltip({ title, children, ...rest }: TooltipProps) {
  // make title html elements
  // if (title && typeof title === "string" && title?.length > 0) {
  /** title can be react element (used in maps on markers) */
  if (title) {
    return (
      <MuiTooltip title={title} arrow placement="bottom-start" {...rest}>
        <span>{children}</span>
      </MuiTooltip>
    );
  }
  return <>{children}</>;
}
