export type LoginProps = {
  email: string;
  password: string;
};
export type RegisterProps = {
  name: string;
  email: string;
  password: string;
};

export type ErrorProps = {
  code: string;
  message: string;
};

/** set uuid key to unreadable string, uuid is used to encrypt user data */
const upa = ["4", "3", "9", "S", "W", "v", "w", "g", "v"]
export const _UP_ = upa.join("")
