import { LazyMotion } from "framer-motion";

// ----------------------------------------------------------------------
 
const loadFeatures = () => import("./features.js").then((res) => res.default);

type Props = {
  children: React.ReactNode;
};

function MotionLazy({ children }: Props) {
  return (
    <LazyMotion strict features={loadFeatures}>
      {children}
      {/* <m.div style={{ background: "red", minHeight: "100vh" }}> {children} </m.div> */}
    </LazyMotion>
  );
}

export default MotionLazy;
