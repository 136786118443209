/**
 * 
 * @param number  - number
 * @param props - NumberFormat props
 * @returns 
 * @example
 
(1234, { notation: "compact" }) // 1.2K

'de-DE', { style: 'currency', currency: 'EUR' }) // "123.456,79 €"

// The Japanese yen doesn't use a minor unit
'ja-JP', { style: 'currency', currency: 'JPY' }) // "￥123,457"

// Limit to three significant digits
'en-IN', { maximumSignificantDigits: 3 }) // "1,23,000"
 */
export function formatNumber(number: number, props?: { [key: string]: string }) {
  if (Number(number)) {
    const formatter = Intl.NumberFormat("en", { notation: "compact", ...(props || {}) });
    return formatter.format(number);
  }
  return 0
}

