

import { ClientError } from "src/model/shared/client-error";

// import axiosInstance from "./instance";


export const axiosErrorLogger = (error: ClientError) => {
    error?.update({
        version: import.meta.env.VITE_VERSION,
        location: window?.location
    })
    if (error?.isValid && error.isValid()) {
        console.error("error", { error })
        // if(import.meta.env.MODE === "production"){
        // axiosInstance.post("/error", error)
        //     .catch((err) => {
        //         console.log("post error err", err);
        //     });
        // }
    }
};


// type TrueFalseType = "true" | "false";
// const checkForChunkLoadError = (props: PlainObject): string | void => {
//   if (typeof props === "object") {
//     if (props?.name && props?.name === "ChunkLoadError") {
//       let CHUNK_LOAD_ERROR =
//         (window.sessionStorage.getItem("ChunkLoadError") as TrueFalseType) || "false";

//       if (CHUNK_LOAD_ERROR === "false") {
//         // console.log("CHUNK_LOAD_ERROR is false, reloading", CHUNK_LOAD_ERROR)
//         setTimeout(() => {
//           window.sessionStorage.setItem("ChunkLoadError", "true");
//           window.location.reload();
//         }, 2000);
//         // window.sessionStorage.setItem("ChunkLoadError", "true")
//         // window.location.reload()
//       } else {
//         // console.log("CHUNK_LOAD_ERROR is true, no reload", CHUNK_LOAD_ERROR)
//         return props?.name || props?.message;
//       }
//     }
//   }
// };


// const isChunkLoadError = (p: any) => {

//   if (typeof p === "object") {
//     const { name, code } = p || {}
//     if (name === "ChunkLoadError") {
//       return true;
//     } else if (code === "CSS_CHUNK_LOAD_FAILED") {
//       return true;
//     } else if (code?.toLowerCase()?.includes("chunk")) {
//       return true;
//     }
//   }

//   return false;
// }

// const includesErrorKeys = (str: string) => {
//   const keys = ["FieldsError", "ChunkLoadError"];

//   let s = str;
//   if (typeof str !== "string") {
//     s = String(str);
//   }

//   let sa = s.split(" ")

//   return sa.some(e => keys.includes(e))
// }

