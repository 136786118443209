import { lazy } from "react";
const SimpleLayout = lazy(() => import("src/layouts/simple"));
const AuthLayout = lazy(() => import("src/modules/auth/views/auth-layout"));
const GuestGuard = lazy(() => import("src/modules/auth/guard/guest-guard"));

// const LoginPage = lazy(() => import("src/modules/auth/components/login"));
// const RegisterPage = lazy(() => import("src/modules/auth/components/register"));
const VerifyEmail = lazy(() => import("src/modules/auth/components/verify-email"));
const ResetPasswordPage = lazy(() => import("src/modules/auth/components/password-reset"));

export const authRoutes = [
  {
    path: "/auth",
    subheader: "auth",
    element: (
      <GuestGuard>
        <SimpleLayout>
          <AuthLayout />
        </SimpleLayout>
      </GuestGuard>
    ),
    children: [
      // { path: "login", element: <LoginPage /> },
      // { path: "register", element: <RegisterPage /> },
      { path: "password-reset", element: <ResetPasswordPage /> },
      { path: "verify-email", element: <VerifyEmail /> } // user is logged in firebase but not in db 
    ],
  },

];
