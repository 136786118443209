import { useEffect } from "react";

type LoadingIndicatorProps = {
  children?: React.ReactNode;
  onIsLoading?: (isLoading: boolean) => void;
};

/**
 * 
 * @param param0 
 * @returns 
 * @example 
  
  
  const SuspenseIconButton = ({ children, ...rest }: IconButtonProps) => {
    const [isLoading, setIsLoading] = useState(false);
    return (
      <>
        <IconButton isLoading={isLoading} {...rest} />
        <ReactSuspense fallback={<LoadingIndicator onIsLoading={setIsLoading} />}>
          {children}
        </ReactSuspense>
      </>
    );
  };
 
 */
const LoadingIndicator = ({ onIsLoading, children }: LoadingIndicatorProps) => {
  useEffect(() => {
    if (onIsLoading) onIsLoading(true);

    return () => onIsLoading && onIsLoading(false);
  }, []);

  if (children) return <>{children}</>;
  return null;
};
export default LoadingIndicator;
