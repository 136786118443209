
import axios from "src/core/utils/axios";
import { ClientError } from "src/model/shared/client-error";



const logError = (error: ClientError) => {
  // logToNotification("error", a, { error: a, stack: !!a.stack && a.stack, body: b })
  // if(typeof path === "string"){
  //   path = "ce:" + path
  // }
  try {
    axios.error(error)
  } catch {/** */ }
  // console.log(a, a)
}

if (import.meta.env.PROD) {

  console.error = (title, ...args) => {
    let t = "console.error";
    let error = args

    if (typeof title === "string") {
      t = title;
    } else {
      error = title;
    }


    logError(new ClientError({
      title: t,
      error
    }))
  };

  if (import.meta.env.VITE_ENV === "prod") {
    console.log = () => { };
    console.debug = () => { };
    console.warn = () => { };
  }

  window.addEventListener("error", (event) => {
    // console.log("window error", event)

    // JSON.stringify deals only with enumerable properties but Error object stores the contextual data in inenumerable properties.
    // To fix this problem
    // There are number of solutions but this one could be straight forward
    // JSON.stringify(err, ["message", "arguments", "type", "name"])
    // This picks the properties you want and generate the string for you.
    const items = { ...localStorage };
    logError(new ClientError({
      title: "EventListener error", error:
        JSON.stringify(event, ["message", "arguments", "type", "name"]),
      props: {
        props: { localStorage: items }
      }
    }));
  });

  window.addEventListener("unhandledrejection", (event: PromiseRejectionEvent) => {
    logError(new ClientError({ title: "EventListener unhandledrejection", error: event?.reason, props: event as any }));
  });
}
// console.log = logDebug
// console.debug = logDebug
// console.warn = logWarning
// console.error = logError

// window.addEventListener("pageshow", (event) => {
//   if (event.persisted) {
//     console.log("This page was restored from the bfcache.");
//   } else {
//     console.log("This page was loaded normally.");
//   }
// });

// window.addEventListener("pagehide", (event) => {
//   if (event.persisted) {
//     console.log("This page *might* be entering the bfcache.");
//   } else {
//     console.log("This page will unload normally and be discarded.");
//   }
// });
