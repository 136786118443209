import { appConfig } from "src/config/config";
import { clearStorage } from "./storage";
import { getItem, setItem } from "./storage-local";

/** *********************** end initialize app ********************** */
const getVersion = (str: string) => {
  try {
    const arr = str.split(".");
    arr.pop();
    return arr.join(".")
  } catch {/**/ }

  return undefined
};


export const initializeApp = async () => {
  const app: any = getItem("version");

  if (app) {
    const currentVersion = getVersion(appConfig.version || "");
    const previousVersion = getVersion(app);
    // console.log("app isEqual", { app, currentVersion, previousVersion });
    if (previousVersion && currentVersion !== previousVersion) {
      clearStorage();
    }
  }
  setVersion()
};

const setVersion = () => {
  setItem("version", appConfig.version);
}
/** *********************** end initialize app ********************** */
/** *********************** local storage ********************** */

