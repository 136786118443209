import { useState, useEffect } from "react";

const useDarkMode = (): boolean => {
    const [isDarkMode, setIsDarkMode] = useState<boolean>(false);

    useEffect(() => {
        const matchMedia = window.matchMedia("(prefers-color-scheme: dark)");

        const handleChange = (e: MediaQueryListEvent) => {
            setIsDarkMode(e.matches);
        };

        // Set initial value
        setIsDarkMode(matchMedia.matches);

        // Listen for changes
        matchMedia.addEventListener("change", handleChange);

        return () => {
            matchMedia.removeEventListener("change", handleChange);
        };
    }, []);

    return isDarkMode;
};

export default useDarkMode;