import { alpha } from "@mui/material/styles";
import { ClientError } from "src/model/shared/client-error";
// import "./type"

export function getColors(palette: any) {
  if (!palette) return undefined;
  const { mode } = palette || {};

  /* 
  palette.background.default doesnt work well on photos
  overlay needs to be dark colors for light and dark themes
  */
  palette.background.overlay = alpha(palette.grey[mode === "dark" ? "800" : "400"], 0.6);

  // "error"
  ["primary", "secondary", "success", "info", "warning", "error"].forEach((color) => {
    try {
      if (palette[color]) {
        // const cm = palette[color][mode];
        const primary = palette[color]?.main; // is needed for primary.main contrast
        const contrastText = palette.getContrastText(primary);

        const inverted = palette[color][mode === "dark" ? "light" : "dark"];
        // console.log(color,"---", palette[color].main)
        palette[color] = {
          ...palette[color],

          /** theres issue with digital display */
          // inverted: alpha(contrastText, 0.8),
          inverted,
          bg: palette[color][mode === "dark" ? "dark" : "light"],
          /** end  */

          // default: hexToRgb(primary,1),
          // contrastText: contrastText,
          contrastText,
        };
        // console.log(color,":::",  palette[color].main)
      }
    } catch (err) {
      console?.error(new ClientError({ title: "theme colors err", error: err }));
    }
  });
  return palette;
}
