import { forwardRef } from "react";

import Box from "@mui/material/Box";

import { IconProps } from "./types";
import { getIconPath, getIconSize } from "./utils";

const Icon = forwardRef<any, IconProps>(({ icon, size, sx }, ref) => {
  let computedSize = ".9em";
  if (size) {
    computedSize = String(getIconSize(size));
  }

  const src = icon && getIconPath(icon);

  if (icon && !src) {
    console.warn("Invalid icon", icon);
  }
  if (icon && icon.includes("ic-"))
    return (
      <Box
        component="embed"
        src={src}
        ref={ref}
        sx={{
          width: computedSize,
          height: computedSize,
          stroke: "currentColor",
          fill: "currentColor",
          //   strokeWidth: "0",
          //   display: "inline-block",
          // mask: `url(${src}) no-repeat center / contain`,
          // WebkitMask: `url(${src}) no-repeat center / contain`,
          ...sx,
        }}
      />
    );

  return (
    <Box
      component="span"
      ref={ref}
      sx={{
        // typography: { xs:"h6", md: "h5" },
        width: computedSize,
        height: computedSize,
        stroke: "currentColor",
        // fill: "inherit",
        strokeWidth: "0",
        display: "inline-block",
        bgcolor: "currentColor",
        mask: `url(${src}) no-repeat center / contain`,
        WebkitMask: `url(${src}) no-repeat center / contain`,
        ...sx,
      }}
    />
  );
});

export default Icon;
