import ICONS from "src/model/app/images/icons.json";

import { IconType } from "src/model/app/images/iconTypes";
import { IconSize } from "./types";

const publicUrlPrefix = import.meta.env.VITE_PUBLIC_URL_PREFIX
// const publicFolder = publicUrlPrefix ? `/static` : `/public/default`
const publicFolder = `${publicUrlPrefix}/public/default`
export const getIconPath = (icon: IconType | string) => {

  for (const [key, value] of Object.entries(ICONS)) {
    if ((value as any).includes(icon)) {

      return `${publicFolder}/icons${key}/${icon}.svg`;
      // return `/static/icons/${key}/${icon}.svg`;
    }
  }
  return ""
};


export const getIconSize = (size: IconSize) => {
  let width = 0.9;
  switch (size) {
    case "xxs":
      width = 0.5;
      break;
    case "xs":
      width = 0.7;
      break;
    case "sm":
      width = 0.8;
      break;
    case "lg":
      width = 1.2;
      break;
    case "xl":
      width = 1.3;
      break;
    case "2x":
      width = 1.5;
      break;
    case "thumbnail":
      width = 74;
      break;
    default:
      width = 0.9;
  }
  if (width < 74) {
    return `${width}em`;
  }
  return width;
};
