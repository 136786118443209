import pick from "lodash-es/pick";
import { useState } from "react";
import { getItem, setItem } from "../utils/storage-local";
import useLocalStorage from "./useLocalStorage";

// ----------------------------------------------------------------------
const NAME = "settings"
export function getLocalSettings<ValueType>(key: string, defaultValue: ValueType) {
  const settings = getItem(NAME, {})
  return settings[key] || defaultValue;
}
export function setLocalSettings<ValueType>(key: string, value: ValueType) {
  const settings = getItem(NAME, {})
  settings[key] = value
  setItem(NAME, settings);
}

export function useLocalSettings<ValueType>(key: string, defaultValue: ValueType) {
  const [settings, setSettings] = useLocalStorage(NAME, { [key]: defaultValue });

  const setTab = (value: ValueType) => {


    const nls = { ...settings };
    nls[key] = value;
    setSettings(nls);
  }


  return [settings[key] || defaultValue, setTab]
}

/**
 * @description - Saves only specified keys to storage, all others are saved to temporary state
 * @param key - settings key
 * @param defaultValue - default state
 * @param saveKeys - keys saved to storage
 * @returns 
 * @example
 * const [state, setState] = useLocalSettingsHybrid<PrintableOptionsType>(
    "ptgp",
    {advanced: true, dateFormat: "MMM ", timeFormat: "HH:mm"}, //default state
    ["advanced","timeFormat"] // only saving these fields to storage
  );
 */
export function useLocalSettingsHybrid<ValueType>(key: string, defaultValue: ValueType, saveKeys: string[]): [ValueType, any] {
  const [state, setStateDefault] = useState<ValueType>(() => {
    const savedState = getLocalSettings(key, {})
    return { ...defaultValue, ...pick(savedState, saveKeys) }
  })

  const storage = {
    compare: (newState: ValueType) => {
      const ns = storage.get(newState)
      const os = JSON.stringify(storage.get(state))
      if (JSON.stringify(ns) !== os) {
        return ns
      }
      return undefined
    },
    get: (thisState: ValueType) => {
      return pick(thisState, saveKeys);
    },
    save: (newState: ValueType) => {
      setStateDefault(newState)
      const cleanState = storage.compare(newState);
      if (cleanState) {
        setLocalSettings(key, cleanState)
      }
    },
  }

  return [state, storage.save]
}
