import { PlainObject } from "src/model";

export class FieldsError extends Error {
  status_code = 422;

  name = "FieldsError";

  fields: PlainObject;

  params: PlainObject;

  constructor(msg: string, fields: PlainObject, params: PlainObject) {
    super();

    this.message = msg;
    this.fields = fields;
    this.params = params;

    try {
      Error.captureStackTrace(this, this.constructor);

    } catch {/**/ }
  }
}