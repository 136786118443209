import localforage from "localforage";
import forEach from "lodash-es/forEach";

localforage.config({
  /* https://localforage.github.io/localForage/#settings-api-config */
  name: "lf-db", // Name of the database
  storeName: "lf-st", // Name of the data store
  size: 50 * 1024 * 1024, // Size of the database in bytes (5 MB in this example)
  driver: [localforage.INDEXEDDB, localforage.LOCALSTORAGE], // Preferred storage drivers in order

  version: 2.1, // Database version //
});

export const setItemAsync = async <T,>(path: string, data: T, password?: string) => {
  try {
    if (path) {
      if (data) {
        const storedData = { isValid: true, data };
        let sd: any = storedData;
        if (password) {
          sd = await encryptJSON(storedData, password);
        }
        await localforage.setItem(path, sd);
        // await localforage.setItem(path, storedData);
      } else {
        await localforage.removeItem(path);
      }
    }
  } catch (err) {/** */
    console.error("setItemAsync", { path, data, err })
  }
};
export const getItemAsync = async <T,>(path: string, defaultValue?: T, password?: string) => {
  if (path) {
    try {
      let item: any = await localforage.getItem(path);

      if (password) {
        item = await decryptJSON(item, password);
      }

      const { isValid, data } = item || {};
      if (isValid) {
        return data;
      }
    } catch (err) {
      console.warn("storage getItemAsync", err);
      /** */
    }
  }

  return defaultValue;
};

export const clearStorage = () => {
  localforage.clear();
  // sessionStorage.clear();
  const keep: any = {};
  /** load */
  const keepStorageItems = ["settings", "theme", "uuid"];
  const keys = Object.keys(localStorage);
  forEach(keys, (k) => {
    forEach(keepStorageItems, (item) => {
      if (k.includes(item)) {
        keep[k] = localStorage.getItem(k);
      }
    });
  });
  localStorage.clear();

  forEach(keep, (v, k) => {
    localStorage.setItem(k, v);
  });
};
/* ########################### ENCRYPTION ########################## */
// Convert hex string to Uint8Array
function hexStringToUint8Array(hexString: string): Uint8Array {
  // Remove any non-hex characters (like spaces or dashes)
  const cleanedHexString = hexString.replace(/\s+/g, "").toLowerCase();

  // Check if the hex string length is even
  if (cleanedHexString.length % 2 !== 0) {
    throw new Error("Invalid hex string length");
  }

  // Convert the hex string to a byte array
  const byteArray = new Uint8Array(cleanedHexString.length / 2);
  for (let i = 0; i < cleanedHexString.length; i += 2) {
    byteArray[i / 2] = parseInt(cleanedHexString.substr(i, 2), 16);
  }

  return byteArray;
}

// Convert Uint8Array to hex string
function uint8ArrayToHexString(array) {
  return Array.prototype.map.call(array, (x) => `00${x.toString(16)}`.slice(-2)).join("");
}

// Generate a cryptographic key from a password
async function getKeyFromPassword(password, salt) {
  const encoder = new TextEncoder();
  const keyMaterial = await window.crypto.subtle.importKey(
    "raw",
    encoder.encode(password),
    { name: "PBKDF2" },
    false,
    ["deriveKey"]
  );

  return window.crypto.subtle.deriveKey(
    {
      name: "PBKDF2",
      salt: encoder.encode(salt),
      iterations: 100000,
      hash: "SHA-256",
    },
    keyMaterial,
    { name: "AES-GCM", length: 256 },
    true,
    ["encrypt", "decrypt"]
  );
}

// Encrypt a JSON object
async function encryptJSON(jsonObject, password) {
  const encoder = new TextEncoder();
  const iv = window.crypto.getRandomValues(new Uint8Array(12));
  const salt = window.crypto.getRandomValues(new Uint8Array(16));
  const key = await getKeyFromPassword(password, uint8ArrayToHexString(salt));
  const jsonString = JSON.stringify(jsonObject);
  const encodedJson = encoder.encode(jsonString);
  const encrypted = await window.crypto.subtle.encrypt(
    {
      name: "AES-GCM",
      iv,
      tagLength: 128,
    },
    key,
    encodedJson
  );

  return {
    iv: uint8ArrayToHexString(iv),
    salt: uint8ArrayToHexString(salt),
    data: uint8ArrayToHexString(new Uint8Array(encrypted)),
  };
}

// Decrypt an encrypted JSON object
async function decryptJSON(encryptedData, password) {
  const decoder = new TextDecoder();
  const iv = hexStringToUint8Array(encryptedData.iv);
  const salt = hexStringToUint8Array(encryptedData.salt);
  const data = hexStringToUint8Array(encryptedData.data);
  const key = await getKeyFromPassword(password, uint8ArrayToHexString(salt));

  const decrypted = await window.crypto.subtle.decrypt(
    {
      name: "AES-GCM",
      iv,
      tagLength: 128,
    },
    key,
    data
  );

  return JSON.parse(decoder.decode(decrypted));
}

export { decryptJSON, encryptJSON };
