import merge from "lodash-es/merge";
import { useMemo } from "react";
// @mui
import {
  alpha,
  ThemeProvider,
  createTheme,
  useTheme,
} from "@mui/material/styles";
//
import { useSettingsContext } from "./SettingsContext";
import { getColors } from "../_custom/colors";
import palette from "../palette";
import { defaultSettings } from "./config";

// ----------------------------------------------------------------------

type Props = {
  children: React.ReactNode;
};

export default function ThemeColorPresets({ children }: Props) {
  const outerTheme = useTheme();

  const { themeColorPrimary } = useSettingsContext();

  const themeOptions = useMemo(
    () => ({
      palette: {
        primary: {
          main: themeColorPrimary || defaultSettings.themeColorPrimary,
        },
      },
      customShadows: {
        primary: `0 8px 16px 0 ${alpha(
          themeColorPrimary || palette("light").primary?.main,
          0.24
        )}`,
      },
    }),
    [themeColorPrimary]
  );

  if (outerTheme?.palette?.primary) {
    /** bg colors are not being created by mui because they previously exist so we have to delete them */
    // @ts-ignore
    delete outerTheme.palette.primary;
  }
  const theme = createTheme(merge(outerTheme, themeOptions));
  theme.palette = getColors(theme.palette);

  return <ThemeProvider theme={theme}>{children}</ThemeProvider>;
}
