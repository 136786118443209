import { Theme } from "@mui/material/styles";

// ----------------------------------------------------------------------

export default function Paper(theme: Theme) {
  return {
    MuiPaper: {
      defaultProps: {
        elevation: 0,
      },
      styleOverrides: {
        root: {

          borderRadius: theme.shape.borderRadius * 1.5,
          backgroundImage: "none",
          "&.MuiAppBar-root": {
            borderRadius: 0, // full screen dialog
            color: theme.palette.text.primary,
            backgroundColor: "transparent",
            // backgroundColor: theme.palette.background.paper //doesnt play well with contrast
            "&.MuiAppBar-root": {
              color: theme.palette.text.primary,
              backgroundColor: "transparent",
            },
          },
        },
      },
    },
  };
}
