

import { getItem, setItem } from "src/core/utils/storage-local";
import { nanoid } from "nanoid";
import { _UP_ } from "../types";


export const getUUID = () => {
    let UUID = getItem(_UP_)?.reverse()?.join("")
    if (!UUID) {
        UUID = nanoid();
        setItem(_UP_, UUID.split("").reverse())
    }

    return UUID
}
