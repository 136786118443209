import { assignValues } from "../utils/assign-values";




export const NOTIFICATIONS = {
    severity: ["info", "warning", "error"] as const
};


type NotificationPropsType = {
    severity?: typeof NOTIFICATIONS.severity[number],
    image?: string;
}

export type NotificationRestProps = {
    sid?: number;
    // severity?: NotificationSeverityType
    isRead?: boolean
    logo?: string;
}

export type NotificationProps = {

    title: string;
    body?: any;
    url?: string;
    uid?: number
    site_logo?: string;

    props?: NotificationPropsType

} & NotificationRestProps

export class Notification {/* firebase only allows strings */
    sid: number = new Date().getTime()

    title: string;

    body?: any;

    props?: NotificationPropsType

    url?: string = ""; // href

    site_logo: string;

    logo?: string = "";

    isRead: boolean = false;

    // created: string = new Date(Date.now() - new Date().getTimezoneOffset() * 60000).toISOString().slice(0, -1)
    // time: number
    created: string;

    changed: number

    uid: number;

    // get = {
    //     // time: () => {
    //     //     const userTimezoneOffset = new Date().getTimezoneOffset() * 60000;
    //     //     return new Date(Number(this.msgid) - userTimezoneOffset).toISOString();
    //     // }
    // };

    constructor(_props: NotificationProps) {
        if (!_props) return;
        this.update(_props)
    }

    update(_props: NotificationProps) {
        assignValues(this, _props);

        if (typeof this.isRead === "string") {
            this.isRead = String(this.isRead) === "true"
        }
        ["sid", "uid", "changed"].forEach(k => {
            if (typeof this[k] === "string") {
                this[k] = Number(this[k])
            }
        })

        this.created = new Date(this.sid).toISOString().slice(0, -1)

        if (_props.sid) this.changed = Number(this.sid);

    }
}


