
/* ************************************ POW ************************************ */
export const POW = {
    URL_FIELDS: [
        "website",
        "facebook",
        "twitter",
        "instagram",
        "youtube",
        "soundcloud",
    ] as const,
};
