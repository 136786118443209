
// export type FLAG = {
//   type:  typeof FLAG.type[number],
//   fetype: typeof FLAG.fetype[number],
// }

import { FlagProps, FlagEntityType, FlagType } from "./flag-types";
import { assignValues } from "src/model/shared/utils/assign-values";

/**
  posts: like, save
  groups: follow 

 */

export class Flag implements FlagProps {
    eid: number;

    fetype: FlagEntityType | string;

    type: FlagType;

    title: string;

    url: string;

    logo?: string;

    cover?: any[]

    constructor(props: FlagProps | null) {
        if (props) {
            assignValues(this, props);
            // validator.validate(this, FlagSchema);
        }
    }
}
