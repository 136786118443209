import { ClientError } from "src/model/shared/client-error";

const asyncHandler = (fn: any, onErrorFun?: any) =>
  function asyncUtilWrap(...args) {
    const fnReturn = fn(...args)

    return Promise.resolve(fnReturn)
      .catch(err => {

        if (err !== null) {
          if (import.meta.env.DEV) {
            console?.error(new ClientError({ title: "asyncHandler err:", error: err }));
          }
        }
        if (onErrorFun) {
          return onErrorFun(err);
        }
        return undefined
      })
  }

export default asyncHandler