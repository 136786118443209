// import { DrawerProps } from "@mui/material";

// ----------------------------------------------------------------------

export default function Divider() {

  return {
    MuiDivider: {
      styleOverrides: {
        root: {
          borderBottomWidth: 1.5,
        }
      },
    },
  };
}
