import { Theme } from "@mui/material/styles";
//
import Divider from "./Divider";
import SpeedDial from "./SpeedDial";

import Alert from "./Alert";
import Avatar from "./Avatar";
import Badge from "./Badge";
import Button from "./Button";
import Card from "./Card";
import Chip from "./Chip";
import Dialog from "./Dialog";
import Drawer from "./Drawer";
import Fab from "./Fab";
import Input from "./Input";
import Link from "./Link";
import Lists from "./List";
import Menu from "./Menu";
import Paper from "./Paper";
import Radio from "./Radio";
import Rating from "./Rating";
import Slider from "./Slider";
import Table from "./Table";
import Tabs from "./Tabs";
// import Switch from './Switch';
import Backdrop from "./Backdrop";
import DataGrid from "./DataGrid";
import Popover from "./Popover";
import Progress from "./Progress";
import Select from "./Select";
import Skeleton from "./Skeleton";
import Stepper from "./Stepper";
import SvgIcon from "./SvgIcon";
import Timeline from "./Timeline";
import Tooltip from "./Tooltip";
import TreeView from "./TreeView";
// import Checkbox from "./Checkbox";
import Accordion from "./Accordion";
import Autocomplete from "./Autocomplete";
import Breadcrumbs from "./Breadcrumbs";
import ButtonGroup from "./ButtonGroup";
import ControlLabel from "./ControlLabel";
import DatePicker from "./DatePicker";
import LoadingButton from "./LoadingButton";
import Pagination from "./Pagination";
import ToggleButton from "./ToggleButton";
import Typography from "./Typography";

// ----------------------------------------------------------------------

export default function ComponentsOverrides(theme: Theme) {
  return Object.assign(
    SpeedDial(theme),
    Divider(),
    Fab(theme),
    Tabs(theme),
    Chip(theme),
    Card(theme),
    Menu(theme),
    Link(),
    Input(theme),
    Radio(theme),
    Badge(),
    Lists(theme),
    Table(theme),
    Paper(theme),
    Alert(theme),
    // Switch(theme),
    Select(),
    Button(theme),
    Rating(theme),
    Dialog(theme),
    Avatar(theme),
    Slider(theme),
    Drawer(theme),
    Stepper(theme),
    Tooltip(theme),
    Popover(theme),
    SvgIcon(),
    // Checkbox(theme),
    DataGrid(theme),
    Skeleton(theme),
    Timeline(theme),
    TreeView(theme),
    Backdrop(theme),
    Progress(theme),
    Accordion(theme),
    DatePicker(),
    Typography(theme),
    Pagination(theme),
    ButtonGroup(theme),
    Breadcrumbs(theme),
    Autocomplete(theme),
    ControlLabel(theme),
    ToggleButton(theme),
    LoadingButton()
  );
}
