import { alpha, Theme } from "@mui/material/styles";

// ----------------------------------------------------------------------

export default function Input(theme: Theme) {
  const rootStyle = ({ ownerState }: any) => {
    /* form control on error */

    const color = ownerState?.formControl?.error ? "error.main" : "inherit";
    return {
      color,
      "&.Mui-focused": {
        color,
      },
    };
  };

  return {
    MuiTextField: {
      defaultProps: {
        variant: "standard",
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: {

          "&.Mui-disabled": {
            "& svg": {
              color: theme.palette.text.disabled,
            },
          },
        },
        input: {
          "&::placeholder": {
            // opacity: 1,
            // color: theme.palette.text.disabled,
          },
          "&:-webkit-autofill": {
            // browser fills in background to white on autofill
            paddingLeft: 5,
            WebkitBoxShadow: `0 0 0 100px ${theme.palette.background.default} inset`,
            WebkitTextFillColor: theme.palette.text.primary,
            WebkitTransitionDelay: "9999s",
            transitionDelay: "9999s",
          },
        },
      },
    },
    MuiInput: {
      styleOverrides: {
        underline: {
          "&:before": {
            borderBottomColor: alpha(theme.palette.grey[500], 0.56),
          },
          "&:after": {
            borderBottomColor: theme.palette.text.primary,
          },
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: ({ ownerState }: any) => rootStyle({ ownerState }),
      },
    },
    MuiFormControl: {
      styleOverrides: {
        root: {
          margin: 0,
          "&.MuiTextField-root": {
            margin: 0, // form textfields and hanging outside of cards
          },
          "&.MuiFormControl-root": {
            margin: 0, // form textfields and hanging outside of cards
          },
        },
      },
    },
    MuiFilledInput: {
      styleOverrides: {
        root: {
          borderRadius: theme.shape.borderRadius,
          // backgroundColor: alpha(theme.palette.grey[500], 0.08),
          // "&:hover": {
          //   backgroundColor: alpha(theme.palette.grey[500], 0.16),
          // },
          // "&.Mui-focused": {
          //   backgroundColor: alpha(theme.palette.grey[500], 0.16),
          // },
          // "&.Mui-disabled": {
          //   backgroundColor: theme.palette.action.disabledBackground,
          // },
        },
        // underline: {
        //   "&:before, :after": {
        //     display: "none",
        //   },
        // },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          "& .MuiOutlinedInput-notchedOutline": {
            borderColor: alpha(theme.palette.grey[500], 0.32),
          },
          "&.Mui-focused": {
            "& .MuiOutlinedInput-notchedOutline": {
              borderWidth: 1,
              borderColor: theme.palette.text.primary,
            },
          },
          "&.Mui-disabled": {
            "& .MuiOutlinedInput-notchedOutline": {
              borderColor: theme.palette.action.disabledBackground,
            },
          },
        },
      },
    },
  };
}
