
// PLEASE REMOVE `LOCAL STORAGE` WHEN YOU CHANGE SETTINGS.
// ----------------------------------------------------------------------

import type { SettingsValueProps } from "./types";

const darkThemeMq = window.matchMedia("(prefers-color-scheme: dark)");
export const defaultSettings: SettingsValueProps = {
  themeMode: darkThemeMq.matches ? "dark" : "light",
  themeDirection: "ltr",
  themeContrast: "default",
  themeLayout: undefined,
  themeColorPrimary: "#1976d2",
  // themeColorPrimary: darkThemeMq.matches
  // ? palette("dark").primary.main
  // : palette("light").primary.main,
  themeStretch: false,
  themeFontSize: 16,
};
