
import { action, makeObservable, observable } from "mobx";

import { createContext, useContext } from "react";
import {
  FieldEditFormType,
  FormConfirmCardType,
  FormDialogProps,
} from "src/core/components/forms/edit/types";
import { PlainObject } from "src/model";
import { FormType } from "src/views/forms/forms-view/form-types";

// type RouterDialogTypes = {
//   _uuid: string;
//   form: typeof ROUTER_DIALOG.forms[number],
//   dialogProps: {
//       fullScreen?: boolean
//       excludeNavbar: boolean,
//       header?: string,
//       subheader?: string,
//       helperText?: string,
//       cancelLabel?: string,
//       submitLabel?: string,
//       onCancel?: (e?: any)=>Promise<any>,
//       onSubmit?: (e?: any)=>Promise<any>,
//   },
//   data?: { [key: string]: any }
// }

type DialogProps = FormDialogProps | FormConfirmCardType | FieldEditFormType | null;

type FormClassType = {
  form: FormType | null;

  dialog: DialogProps;

  data: PlainObject | null;

  children: React.ReactNode | null;
};
class FormStoreClass {
  forms: FormClassType[] = [];

  form: FormType | null;

  dialog: DialogProps | null;

  data: PlainObject | null;

  children: React.ReactNode | null;

  /**
   *
   * @param form
   * @param props
   * @param data
   * @examples   ```javascript
  FormStore.setForm("event", {header: "my custom header"}, { node: node })
  FormStore.setForm("event", title, { reid: 123})
    
FormStore.setForm("confirm", {
    icon: icon,
    header: "",
    subheader: subheader,
    submitLabel: "OK",
    onSubmit: async () => {
      FormStore.clearForm();
    },
} as FormDialogBlockProps);
  
  FormStore.setForm("form",{
      type: "TextField", 
      additionalProps: {
        multiline:true, 
        rows:4,
        label:"About",
        variant:"filled" 
      },
      defaultValue: user.props.about,
      onSubmit: async(value: string)=>{
        await update.user({ props:{ about: value } })
      }
    } as FormDialogBlockProps,
  )
 ```
*/

  setForm(
    _form: FormType,
    _dialog?: DialogProps,
    _data?: PlainObject,
    _children?: React.ReactNode
  ) {
    if (this.form) {
      const form: FormClassType = {
        form: this.form,
        dialog: this.dialog,
        data: this.data,
        children: this.children,
      };
      this.forms.push(form);
    }

    this.form = _form || null;
    this.dialog = _dialog || null;
    this.data = _data || null;
    this.children = _children || null;

    /** handle back button */
    // window.location.hash = "#form";
    // window.addEventListener("popstate", handleNavigationBack);
  }

  clear = {
    store: () => {
      this.forms = [];
      this.form = null;
      this.dialog = null;
      this.data = null;
      this.children = null;
    },
  };

  clearForm() {
    // window.removeEventListener("popstate", handleNavigationBack);

    if (!this) {
      console.error(
        "DO NOT USE  onCancel=FormStore.clearForm, this looses context, instead use onCancel=()=>FormStore.clearForm()"
      );
    }
    const form = this.forms.pop(); // remove last;
    this.form = form?.form || null;
    this.dialog = form?.dialog || null;
    this.data = form?.data || null;
    this.children = form?.children || null;
    /** handle back button */
    // goBackOnHash();
  }

  setDialog(_dialog?: PlainObject) {
    this.dialog = _dialog ? { ...this.dialog, ..._dialog } : null;
  }

  constructor() {
    makeObservable(this, {
      // clear: action,
      setForm: action,
      clearForm: action,
      form: observable,
      dialog: observable,
      data: observable,
      children: observable,
    });
  }
}

export const FormStore = new FormStoreClass();

const FormContext = createContext(FormStore);

type Props = {
  children: React.ReactNode;
};
export const FormProvider = ({ children }: Props) => (
  <FormContext.Provider value={FormStore}>{children}</FormContext.Provider>
);

export const useFormContext = () => {
  const context = useContext(FormContext);

  if (!context) throw new Error("useFlagContext must be use inside UserProvider");

  return context;
};
