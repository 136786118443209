import { createContext, ReactNode, useContext } from "react";
import FlagStore from "./store/flag_store";


const FlagContext = createContext(FlagStore);

type Props = {
  children: ReactNode;
};
export const FlagProvider = ({ children }: Props) => (
  <FlagContext.Provider value={FlagStore}>{children}</FlagContext.Provider>
);

export const useFlagContext = () => {
  const context = useContext(FlagContext);

  if (!context) throw new Error("useFlagContext must be use inside UserProvider");

  return context;
};
export { FlagStore };
