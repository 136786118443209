import { useEffect, useState } from "react";
import AppLoadingScreen from "src/components/logo/LoadingScreen";





const loadingStyle = {
  default: {
    minHeight: "20vh",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
};

const LoadingScreen = ({ showWithoutDelay = false, size, ...rest }: any) => {
  const [show, setShow] = useState(showWithoutDelay);

  useEffect(() => {
    if (show) return;
    setTimeout(() => {
      setShow(true);
    }, 1000)
  }, [])

  if (!show) return null;
  return (
    <div style={loadingStyle.default}>
      <AppLoadingScreen size={size} {...rest} />
    </div>
  )
};
export default LoadingScreen