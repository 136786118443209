
export const getURL = (url: string, defaultUrl?: string) => {
    if (url && typeof url === "string") {
        if (!url.includes("http") && url.substring(0, 8) !== "/static/") {
            return (import.meta.env.VITE_PUBLIC_URL_PREFIX || "") + url
        }
        return url;
    }

    return defaultUrl || "";
};

export const getThumbnail = (src: string) => {
    if (!src || typeof src !== "string") return "";

    if (src.includes("/thumbnail/")) {
        return src;
    }
    let newSrc = src.replace("/image/", "/image/thumbnail/");
    newSrc = newSrc.replace("/thumbnail/thumbnail/", "/thumbnail/");
    return newSrc;

};