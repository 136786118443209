import Stack from "@mui/material/Stack";
import type { ListItemType } from "src/core/components/list/types";
import HighlightText from "src/core/components/highlight-text";
import Icon from "src/core/components/icon/icon";
import IconButton from "src/core/components/icon/icon-button";
import Avatar from "src/core/components/avatar/Avatar";




export const searchListItemDefault = (r: any, value?: string): ListItemType => {
  const li: ListItemType = {
    // icon: r?.logo ? <Avatar src={r.logo} /> : <Icon icon="globe" size="2x"/>,
    icon: (
      <Avatar
        src={r?.logo}
        sx={{ border: "1px solid", borderColor: "divider", bgcolor: "background.neutral" }}
        children={<Icon icon="globe" size="lg" sx={{}} />}
      />
    ),
    primary: <HighlightText value={value || ""} text={r?.title} />,
    // secondary: <HighlightText value={value} text={r.address?.formatted_address} sx={{ typography: "caption", color: "text.disabled", }}/>,
    secondary: (
      <Stack
        direction="row"
        sx={{ alignItems: "center", typography: "caption", color: "text.disabled" }}
      >
        {r?.address?.formatted_address && (
          <>
            <Icon icon="map" size="lg" sx={{ mr: 1 }} />
            <HighlightText
              value={value || ""}
              text={`${r?.address?.formatted_address || "n/a"} `}
            />
          </>
        )}
        {r?.props?.website && (
          <>
            <Icon icon="globe" size="lg" sx={{ ml: 2, mr: 1 }} />
            <HighlightText value={value || ""} text={`${r?.props?.website || "n/a"}`} />
          </>
        )}
      </Stack>
    ),
    action: <IconButton icon="chevron-right" href={r?.url} />,
  };
  return li;
};
