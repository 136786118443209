
// ----------------------------------------------------------------------

export default function DatePicker() {
  return {
    MuiDatePicker: {
      defaultProps: {
        inputFormat: "dd/MM/yyyy",
      },
    },
  };
}
