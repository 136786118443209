type PlainObject = { [key: string]: any };

/** *********************** local state ********************** */

export const setState = (path: string, state: PlainObject) => {
  if (!path || !state) return;

  state.changed = new Date();
  setItem(path, state);
};
export const getState = (path: string) => {
  if (path) {
    const s = getItem(path);
    return s;
    // try{
    //   const savedDate = new Date(s?.changed);
    //   const currentDate = new Date();
    //   const Difference_In_Time = currentDate.getTime() - savedDate.getTime();
    //   const Difference_In_Hours = Difference_In_Time / (1000 * 3600);

    //   if (s) {
    //     return {
    //       refresh: Difference_In_Hours > 23 ? true : false,
    //       Difference_In_Hours: Difference_In_Hours,
    //       state: s,
    //     };
    //   }

    // }catch {/** */ }
  }

  return undefined;
};
/** *********************** end local state ********************** */

export const setItem = <T,>(path: string, data: T) => {
  if (path) {
    if (data) {
      const str = JSON.stringify(data);
      localStorage.setItem(path, str);
    } else {
      localStorage.removeItem(path);
    }
  }
};
export const getItem = <T,>(path: string, defaultValue?: T) => {
  if (path) {
    const item = localStorage.getItem(path);
    if (item) {
      try {
        const data = JSON.parse(item);
        if (data) {
          return data;
        }
      } catch {
        // console.log("storage getItem failed", item);
        // return item;
      }
    }
  }

  return defaultValue;
};
