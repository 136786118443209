// ----------------------------------------------------------------------
import { Theme } from "@mui/material/styles";

export default function SpeedDial(theme: Theme) {
  return {
    MuiSpeedDialAction: {
      styleOverrides: {
        fab: {
          backgroundColor: theme.palette.background.neutral
        },
        staticTooltipLabel: {
          whiteSpace: "nowrap",
          maxWidth: "none",
          backgroundColor: theme.palette.background.neutral
        },
      },
    },
  };
}
  