import { ReactNode, createContext, useEffect, useContext, useMemo, useCallback } from "react";
// hooks
import useLocalStorage from "../../hooks/useLocalStorage";
//

import { defaultSettings } from "./config";
import { LayoutOptionsType, SettingsContextProps, SettingsValueProps } from "./types";
import useDarkMode from "src/core/theme/_custom/useDarkMode";
// ----------------------------------------------------------------------

const initialState: SettingsContextProps = {
  ...defaultSettings,
  // Mode
  onToggleMode: () => { },
  onChangeMode: () => { },
  // Direction
  onToggleDirection: () => { },
  onChangeDirection: () => { },
  onChangeDirectionByLang: () => { },
  // Layout
  onChangeLayout: () => { },
  // Contrast
  onToggleContrast: () => { },
  onChangeContrast: () => { },
  // Color
  onChangeColor: () => { },
  // Stretch
  onToggleStretch: () => { },
  // Reset
  onResetSetting: () => { },
  onChangeFontSize: () => { },
  onChangeSettings: () => { },
};

// ----------------------------------------------------------------------

export const SettingsContext = createContext(initialState);

export const useSettingsContext = () => {
  const context = useContext(SettingsContext);

  if (!context) throw new Error("useSettingsContext must be used inside SettingsProvider");

  return context;
};

// ----------------------------------------------------------------------

type SettingsProviderProps = {
  children: ReactNode;
};

export function SettingsProvider({ children }: SettingsProviderProps) {
  // const settingsRef = userRef<any>();
  const [settings, setSettings] = useLocalStorage("theme", defaultSettings);
  const { themeMode } = settings || {}
  const isDarkTheme = useDarkMode();

  const langStorage = typeof window !== "undefined" ? localStorage.getItem("i18nextLng") : "";

  const isArabic = langStorage === "ar";
  /**
   * isDarkTheme is set to null and only changes on event listener when user changes mode
   *
   */
  useEffect(() => {
    if (themeMode === "auto") {
      if (isDarkTheme !== null) {
        setSettings({ ...settings, themeMode: isDarkTheme ? "dark" : "light" });
      }
    }
  }, [isDarkTheme]);

  useEffect(() => {
    if (isArabic) {
      onChangeDirectionByLang("ar");
    }

  }, [isArabic]);

  // Direction
  const onToggleDirection = useCallback(() => {
    const themeDirection = settings.themeDirection === "rtl" ? "ltr" : "rtl";
    setSettings({ ...settings, themeDirection });
  }, [setSettings, settings]);

  const onChangeDirection = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const themeDirection = event.target.value;
      setSettings({ ...settings, themeDirection });
    },
    [setSettings, settings]
  );

  const onChangeDirectionByLang = useCallback(
    (lang: string) => {
      const themeDirection = lang === "ar" ? "rtl" : "ltr";
      setSettings({ ...settings, themeDirection });
    },
    [setSettings, settings]
  );

  // Layout
  const onChangeLayout = useCallback(
    (value?: LayoutOptionsType) => {
      try {
        // const themeLayout = event ? event.target.value : undefined;
        setSettings({ ...settings, themeLayout: value });
      } catch {
        /** */
      }
    },
    [setSettings, settings]
  );

  // Contrast
  const onToggleContrast = useCallback(() => {
    const themeContrast = settings.themeContrast === "default" ? "bold" : "default";
    setSettings({ ...settings, themeContrast });
  }, [setSettings, settings]);

  const onChangeContrast = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const themeContrast = event.target.value;
      setSettings({ ...settings, themeContrast });
    },
    [setSettings, settings]
  );

  // Stretch
  const onToggleStretch = useCallback(() => {
    const themeStretch = !settings.themeStretch;
    setSettings({ ...settings, themeStretch });
  }, [setSettings, settings]);

  // Reset
  const onResetSetting = useCallback(() => {
    setSettings(defaultSettings);
  }, [setSettings]);

  // Mode
  const onToggleMode = useCallback(() => {
    const themeMode = settings.themeMode === "light" ? "dark" : "light";
    setSettings({ ...settings, themeMode });
  }, [setSettings, settings]);

  const onChangeMode = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const themeMode = event.target.value;
      setSettings({ ...settings, themeMode });
    },
    [setSettings, settings]
  );

  const onChangeFontSize = useCallback(
    (newFontSize: number) => {
      // let fs = Number(settings?.themeFontSize) || 15;
      // const ib = Number(increment);

      // let newFs = fs + ib;
      // if (ib === 0) {
      //   newFs = 15;
      // } else if (ib > 5) {
      //   newFs = ib;
      // }

      setSettings({ ...settings, themeFontSize: newFontSize });
    },
    [setSettings, settings]
  );
  // Color
  const onChangeColor = useCallback(
    (value: string) => {
      const themeColorPrimary = value;
      setSettings({ ...settings, themeColorPrimary });
    },
    [setSettings, settings]
  );

  const onChangeSettings = useCallback(
    (value: { [k in keyof SettingsValueProps]: SettingsValueProps[k] }) => {
      try {
        /* 
          onChangeSettings({themeLayout: undefined}) doesnt work when we delete the key 
          if not deleting key breaks stuff, then exclude list or primary settings keys
          ex:
        */
        // (Object.keys(value) as Array<keyof SettingsValueProps>).forEach((key) => {
        //   if(!["themeLayout", "themeMode"].includes(key)){
        //     if (!value[key]) {
        //       delete value[key];
        //     }
        //   }
        // });
      } catch {
        /** */
      }
      setSettings({ ...settings, ...value });
    },
    [setSettings, settings]
  );

  const value = useMemo(
    () => ({
      ...settings,
      // Mode
      onToggleMode,
      onChangeMode,
      // Direction
      onToggleDirection,
      onChangeDirection,
      onChangeDirectionByLang,
      // Layout
      onChangeLayout,
      // Contrast
      onChangeContrast,
      onToggleContrast,
      // Stretch
      onToggleStretch,
      // Color
      onChangeColor,
      // Reset
      onResetSetting,
      onChangeFontSize,
      onChangeSettings,
    }),
    [
      settings,
      // Mode
      onToggleMode,
      onChangeMode,
      // Direction
      onToggleDirection,
      onChangeDirection,
      onChangeDirectionByLang,
      // Layout
      onChangeLayout,
      onChangeContrast,
      // Contrast
      onToggleContrast,
      // Stretch
      onToggleStretch,
      // Color
      onChangeColor,
      // Reset
      onResetSetting,
      onChangeFontSize,
      onChangeSettings,
    ]
  );

  return <SettingsContext.Provider value={value}>{children}</SettingsContext.Provider>;
}
