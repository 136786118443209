import { PlainObject } from "..";
import { assignValues } from "./utils/assign-values";



export const NOTIFICATIONS = {
  severity: ["info", "warning", "error"] as const
};

type ClientErrorType = {
  title: string;

  error: any

  props?: {
    component?: string
    path?: string
    version?: string
    location?: PlainObject,
    props?: PlainObject,

  }
}
export class ClientError implements ClientErrorType {
  title: string;


  error: any;

  props: any = {
    version: "",
    component: "",
    path: undefined,
    location: {},
    props: {},
  }






  constructor({ title, props, error }: ClientErrorType) {
    this.title = title || error?.message;
    this.error = error;


    assignValues(this.props, props as any);
    if (window?.location) {
      assignValues(this.props.location, window?.location, ["host", "hostname", "href", "origin", "pathname", "search"]);
    } else if (props?.location) {
      assignValues(this.props.location, props?.location, ["host", "hostname", "href", "origin", "pathname", "search"]);
    }
  }

  update(p: Partial<ClientErrorType["props"]>) {
    const { location, ...props } = p || {}
    if (location) {
      assignValues(this.props.location, location, ["host", "hostname", "href", "origin", "pathname", "search"]);
    }
    assignValues(this.props, props);

  }

  #test = {
    for: (arr: string[], fun: (v: string) => boolean) => {
      for (const v of arr) {
        if (fun(v)) return true;
      }
      return false;
    },
    message: (val: string) => {
      // const { message } = this.error || {}
      // if (message && message?.includes(val)) {
      //   return true;
      // }
      const str = JSON.stringify(this.error || {})
      if (str.includes(val)) {
        return true;
      }

      return false;
    }
  }

  isValid() {
    const invalidMessage = ["chunk", "serviceWorker", "messaging/unsupported-browser"]
    if (this.#test.for(invalidMessage, this.#test.message)) {
      return false;
    }


    return true
  }
}





