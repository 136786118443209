import { useMemo } from "react";
// @mui
import {
  createTheme,
  ThemeProvider as MUIThemeProvider,
  StyledEngineProvider,
  ThemeOptions,

} from "@mui/material/styles";
// components
import { getColors } from "src/core/theme/_custom/colors";
import { useSettingsContext } from "./settings";
//
import palette from "./palette";
import typography from "./typography";
// import shadows from "./shadows";
import customShadows from "./customShadows";
import GlobalStyles from "./globalStyles";
import componentsOverride from "./overrides";
import CssBaseline from "@mui/material/CssBaseline/CssBaseline";
// ----------------------------------------------------------------------

type Props = {
  children: React.ReactNode;
};
let PRINTED = false;
export default function ThemeProvider({ children }: Props) {
  const { themeFontSize, themeMode, themeDirection } = useSettingsContext();

  const themeOptions: ThemeOptions = useMemo(
    () => ({
      palette: palette(themeMode),
      typography: {
        // ...typography,
        ...typography,
        fontSize: themeFontSize,
        // htmlFontSize: themeFontSize,
      },
      shape: { borderRadius: 8 },
      direction: themeDirection,
      // shadows: shadows(themeMode),
      customShadows: customShadows(themeMode),
    }),
    [themeDirection, themeMode, themeFontSize]
  );
  const theme = createTheme(themeOptions);

  theme.components = componentsOverride(theme);
  theme.palette = getColors(theme.palette);
  if (import.meta.env.DEV && !PRINTED) {
    PRINTED = true;
    // setTimeout(()=>{
    // console.log("theme", theme);
    console.log("theme", { ...theme.palette, _rest: theme });
    // },700)
    // console.log("theme", theme.palette)
  }

  return (
    <StyledEngineProvider injectFirst>
      <MUIThemeProvider theme={theme}>
        <CssBaseline />
        <GlobalStyles />
        {children}
      </MUIThemeProvider>
    </StyledEngineProvider>
  );
}
