import MUILink from "@mui/material/Link";
import { NavLink as RouterLink } from "react-router-dom";
import { isExternalLink } from ".";

const linkProps = (href?: string) => {
  if (!href) return { component: "span" };

  if (isExternalLink(href) || href.includes("tel")) {
    return {
      href,
      target: "_blank",
      rel: "noopener",
      component: MUILink,
    };
  }

  return {
    component: RouterLink,
    to: href || "#",
  };
};

export default linkProps